/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/extensions */

import React from "react";

import BrandIcon from "./BrandIcon";
import Button from "../elements/Button";

export default function Footer() {
  return (
    <div className="bg-gray-50 border-t border-gray-200 pb-6">
      <div className="container flex-col mx-auto ">
        <div className="flex flex-col sm:flex-row mt-8 justify-center">
          <div className="w-1/3 flex-col ml-16 mr-8">
            <BrandIcon />
            <p className="w-full text-lg text-gray-400 font-light">
              Empower Your Research <br />
              Is Our Calling
            </p>
          </div>

          <div className="w-1/3 ml-16 sm:ml-0 mt-0">
            <h1 className="text-lg text-theme-blue pt-4 pb-2">Social</h1>
            <Button
              href="https://www.instagram.com/janara_online/"
              type="link"
              target="_blank"
              className="flex text-lg text-gray-400 font-light hover:underline"
              isExternal
            >
              Instagram
            </Button>
          </div>
          <div className="w-1/3 ml-16 sm:ml-0 mt-0">
            <h1 className="text-lg text-theme-blue pt-4 pb-2">Services</h1>
            <Button
              href="#"
              type="link"
              // target="_blank"
              className="flex text-lg text-gray-400 font-light hover:underline"
              isExternal
            >
              Research Releated
            </Button>
            <Button
              href="#"
              type="link"
              // target="_blank"
              className="flex text-lg text-gray-400 font-light hover:underline"
              isExternal
            >
              Web Development
            </Button>
            <Button
              href="#"
              type="link"
              // target="_blank"
              className="flex text-lg text-gray-400 font-light hover:underline"
              isExternal
            >
              App Development
            </Button>
            <Button
              href="#"
              type="link"
              // target="_blank"
              className="flex text-lg text-gray-400 font-light hover:underline"
              isExternal
            >
              AI/ML
            </Button>
            <Button
              href="#"
              type="link"
              // target="_blank"
              className="flex text-lg text-gray-400 font-light hover:underline"
              isExternal
            >
              DataScience
            </Button>
            <Button
              href="#"
              type="link"
              // target="_blank"
              className="flex text-lg text-gray-400 font-light hover:underline"
              isExternal
            >
              IOT Project
            </Button>
          </div>
          <div className="w-1/3 mt-0 ml-16 mr-0 sm:ml-0 sm:mr-5">
            <h1 className="text-lg text-theme-blue pt-4 pb-2">Contact Us</h1>
            <p className="text-lg text-gray-400 font-light">
              support@janarasolutions.site
            </p>
            <p className="text-lg text-gray-400 font-light">+91 8143267596</p>
            <p className="text-lg text-gray-400 font-light">
              Tirupati, Andhra Pradesh, India
            </p>
          </div>
        </div>

        <div className="flex-col text-center mt-7">
          <p className="text-sm text-gray-400 font-light">
            Copyright 2023 - All rights reserved - Janara Solutions
          </p>
        </div>
      </div>
    </div>
  );
}
