/* eslint-disable import/extensions */

// Services Img Imports
import Research from "../assets/images/Services/Research.png";
import Project from "../assets/images/Services/Design.png";
import Web from "../assets/images/Services/Web.png";

// Portfolio Img Imports
import Recruiting from "../assets/images/Portfolio/Recruiting.png";
import Stream from "../assets/images/Portfolio/Stream.png";
import Freelance from "../assets/images/Portfolio/Freelance.png";
import Aura from "../assets/images/Portfolio/Aura.png";
import Surtido from "../assets/images/Portfolio/Surtido.png";
import ManagementApp from "../assets/images/Portfolio/ManagementApp.png";

// Advantages
import Communicative from "../assets/images/Advantages/Communicative.png";
import Collaborative from "../assets/images/Advantages/Collaborative.png";
import Management from "../assets/images/Advantages/Management.png";
import Favorite from "../assets/images/Advantages/Favorite.png";

// Testimonials
import Shivaji from "../assets/images/Testimonials/shivaji.jpeg";
import Yeshwanth from "../assets/images/Testimonials/Yeswanth.jpeg";
import Kruger from "../assets/images/Testimonials/Kruger.jpg";

// TeamMembers
import Founder from "../assets/images/TeamMembers/Founder.jpeg";
import CEO from "../assets/images/TeamMembers/CEO.jpeg";
import Nagarjuna from "../assets/images/TeamMembers/Nagarjuna.jpeg";
import Ganesh from "../assets/images/TeamMembers/ganesh.jpeg";
import Madan from "../assets/images/TeamMembers/Frontend1.jpg";
import Frontend2 from "../assets/images/TeamMembers/Frontend2.jpg";
import Backend1 from "../assets/images/TeamMembers/Backend1.jpg";
import Backend2 from "../assets/images/TeamMembers/Backend2.jpg";
import Mobile1 from "../assets/images/TeamMembers/Mobile1.jpg";

export const Services = [
  {
    title: "Custom Research Paper Writing",
    imageUrl: Research,
    animation: "left",
  },
  {
    title: "Custom Project Report Writing",
    imageUrl: Project,
    animation: "up",
  },
  {
    title: "Capstone Project Development ",
    imageUrl: Web,
    animation: "right",
  },
];

export const Portfolios = [
  {
    id: "asd1293uasdads1",
    title: "Review and Analysis of Adidas Sales in US using Tableau",
    description:
      "In this paper visualization of adidas footwear sales dataset of 2020 and 2021 in US using tableau. Sales strategies in the modern business world, examining the latest research discoveries and industry trends. The paper investigates the dynamic of sales, including strategies, technologies, and consumer behaviors. It explores the impact of digitalization on sales methodologies, highlighting the incorporation of artificial intelligence, big data analytics, and machine learning in automating sales processes and managing customer relationships. Additionally, the study emphasizes the rising influence of social selling and its pivotal role in shaping customer preferences and purchase decisions through social media platforms. The review underscores the significance of personalized selling methods, urging sales professionals to adopt empathetic and customer-focused approaches.",

    imageUrl: Recruiting,
    type: "Research",
    responsibility: ["Data Science", "Research", "Tablue"],
    // credit: "https://dribbble.com/shots/15164950-Recruiting-app",
  },
  {
    id: "asd1293uhjkhkjh2",
    title: "Classification Algorithms on Student Stress Level",
    description:
      "By taking student stress level dataset with observactions 9,314.producing machine learning classification model. Stress is a pervasive and incapacitating psychological condition that affects individuals across various demographic groups. This document introduces an evidence-based strategy to confront the urgent concern of predicting stress levels by harnessing the capabilities of machine learning methodologies. Employing an extensive stress level dataset that encompasses a wide array of physiological, psychological, and contextual attributes, this investigation seeks to construct a predictive classification model. By deploying cutting-edge machine learning algorithms, the model endeavors to classify individuals into discrete stress level categories, with the goal of enabling timely identification and intervention. This abstract further underscores the potential societal consequences of this inquiry, spanning from the enhancement of mental health support to the optimization of stress management strategies.",
    imageUrl: Stream,
    type: "Research",
    responsibility: ["AI/ML", "Research", "CNN"],
    // credit: "https://dribbble.com/shots/15276430-Stream",
  },
  {
    id: "asd1293uvbvcbbd3",
    title: "StepOne: Empowering Rural Youth through Remote Employment ",
    description:
      "StepOne is an innovative platform aimed at empowering youth from rural areas in India by offering remote employment opportunities and skill development programs. The primary goal is to reduce rural-to-urban migration by creating sustainable job options locally. What makes StepOne unique is its holistic approach to career advancement. Unlike traditional platforms that combine internships, jobs, and courses, StepOne initiates with personalized counseling sessions for users at their graduation or post-graduation phase. These sessions identify individual strengths and interests, leading to customized courses covering a wide range of skills. A standout feature of StepOne is its partnerships with companies, enabling cost-effective project execution compared to standard employment models. Utilizing HTML, CSS, React.js, and Firebase technologies, StepOne delivers a seamless user interface, bridging the gap between rural talent and remote job opportunities.",

    imageUrl: Freelance,
    type: "Website",
    responsibility: ["Mobile Development", "Mern stack", "Research"],
    // credit: "https://dribbble.com/shots/15223131-Freelance-Mobile-App-Concept",
  },
  {
    id: "asd1293ufgdfgs4",
    title:
      "Interactive Tool for Speech-to-Visual Sign Language Conversion Using Python",
    description:
      "This project focuses on creating an interactive tool designed to convert spoken language into visual sign language, aiming to improve communication accessibility for those with hearing impairments. The application, built with Python and Flask, integrates several core functionalities: secure user authentication, speech recognition, and dynamic GIF display based on the recognized speech input. It utilizes a range of libraries, including Flask, SQLAlchemy, Bcrypt, Flask-Login, and Speech Recognition, to deliver a cohesive user experience. User authentication is managed through a secure sign-up and login process, incorporating encrypted password storage and session management. Robust form validation is achieved with WTForms, ensuring data integrity. After authentication, users can utilize the assistant feature, where real-time speech recognition is executed using the Speech Recognition library. The recognized speech is then translated into corresponding sign language GIFs for display. This tool offers a practical solution for transforming spoken language into visual sign language representations and highlights the potential of integrating web technologies with speech recognition and image processing to overcome communication barriers. The application's architecture, employing Flask as the web framework and SQLAlchemy for database management, showcases the effectiveness of combining modern web development practices with advanced machine learning techniques to develop impactful assistive technologies.",

    imageUrl: Aura,
    type: "Website",
    responsibility: ["Web Development", "Python", "Research"],
    // credit: "https://dribbble.com/shots/15176338-Aura-Website-Main-Page",
  },
  {
    id: "asd1293ulskmnb5",
    title: "Enhancing Medical Decision-Making with Machine Learning",
    description:
      "This research paper delves into a thorough examination of machine learning methodologies applied within the healthcare domain for diagnostic and prognostic purposes. It encompasses a spectrum of models such as SVM, RFC, neural networks, and CNN. These models were strategically deployed on datasets pertaining to cancer diagnosis, CKD, asthma prediction, heart disease detection, diabetes outcome prediction, pneumonia detection from chest X-ray images, and thyroid disease classification. The study highlights the performance of SVM and RFC models in cancer diagnosis, achieving notable accuracies without compromising precision, recall, and F1-score metrics. A specialized neural network model tailored for asthma prediction exhibited exceptional accuracy levels on validation data, demonstrating the efficacy of its architecture and design choices. Similarly, heart disease detection leveraged neural network capabilities to achieve respectable accuracy rates and along with thyroid disease.",

    imageUrl: Surtido,
    type: "Research",
    responsibility: ["AI/ML", "Research", "CNN"],
    // credit: "https://dribbble.com/shots/15173118-Surtido-Rico",
  },
];

export const Advantages = [
  [
    {
      title: "Communicative",
      description:
        "We communicate our project ideas and progress to make it clear.",
      imageUrl: Communicative,
    },
    {
      title: "Assistance",
      description:
        "We manage your project properly by providing 24/7 assistance.",
      imageUrl: Management,
    },
  ],
  [
    {
      title: "Collaborative​",
      description:
        "Our team are very collaborative to make your project done well.",
      imageUrl: Collaborative,
    },
    {
      title: "Trust",
      description: "Trust is our key and all of our client trust us.",
      imageUrl: Favorite,
    },
  ],
];
export const Testimonials = [
  {
    id: 1,
    name: "Venkata Shivaji Bathina",
    company: "Master of Science Scholar",
    testimoni:
      "Thanks for Janara, you guys are the best! Keep up the great work!",
    imageUrl: Shivaji,
  },
  {
    id: 2,
    name: "Yeshwanth Chowdary",
    company: "Phd Scholar",
    testimoni:
      "I just wanted to let you know that it’s been great working with Janara.",
    imageUrl: Yeshwanth,
  },
  {
    id: 3,
    name: "Rahul Rampure",
    company: "Bachelor of Technology Scholoar",
    testimoni: "Janara is so great. Thank you so much for a job well done.",
    imageUrl: Kruger,
  },
];

export const TeamMembers = [
  {
    name: "Satya Kota",
    position: "Founder",
    imageUrl: Founder,
  },
  {
    name: "Rishitha",
    position: "CEO",
    imageUrl: CEO,
  },
  {
    name: "Nagarjuna",
    position: "Product Manager",
    imageUrl: Nagarjuna,
  },
  {
    name: "Ganesh",
    position: "Research Expert",
    imageUrl: Ganesh,
  },
];

// {
//   name: "Sree Nithin",
//   position: "Content Writer Expert",
//   imageUrl: Backend2,
// },
// {
//   name: "Abhi",
//   position: "UI/UX Designer",
//   imageUrl: Frontend1,
// },
// {
//   name: "Bobby",
//   position: "Fullstack Developer",
//   imageUrl: Frontend2,
// },

// {
//   name: "Nagarjuna",
//   position: "Mobile Developer",
//   imageUrl: Mobile1,
// },
// {
//   name: "Sunil",
//   position: "AI/ML Developer",
//   imageUrl: Backend1,
// },
// {
//   name: "Komali",
//   position: "Data Scientist/Analyst",
//   imageUrl: Backend1,
// },
// {
//   name: "Subba Reddy",
//   position: "Cloud Engineer",
//   imageUrl: Mobile1,
// },
// {
//   name: "Sumit",
//   position: "IOT Engineer",
//   imageUrl: Mobile1,
// },
